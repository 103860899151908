import { countryLocalization } from "@/utils/helpers";
import axios from "axios";
import { createContext, useEffect, useState } from "react";
import countriesAndTimezones from "countries-and-timezones";

const GlobalContext = createContext({});

const GlobalProvider = ({ children }: any) => {
  const [bannerText, setBannerText] = useState([]);
  const [helpModalSave, setHelpModalSave] = useState(false);
  const [userCountry, setUserCountry] = useState("");
  const [country, setCountry] = useState("");
  const [usercountryCode, setUserCountryCode] = useState("");
  const [isCookiePopup, setCookiePopup] = useState(false);
  useEffect(() => {
    loadBanner();
    getUserCountry();
    // navigator.geolocation.watchPosition(successCallback, errorCallback);
  }, []);

  const getTimezone = () => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return timezone;
  };
  const getUserCountry = () => {
    const timezone = getTimezone();
    const country: any = countriesAndTimezones.getCountryForTimezone(timezone);
    setCountry(country?.name);
    setUserCountry(
      countryLocalization[country?.name]
        ? countryLocalization[country?.name]
        : "countryNotFound"
    );
    // setUserCountry('United States of America');
    setUserCountryCode(country?.id?.toLowerCase());
    // setUserCountryCode('us');
  };

  // const successCallback = async (position: any) => {
  //   const latitude = position?.coords?.latitude
  //   const longitude = position?.coords?.longitude
  //   await axios
  //     .get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&sensor=true&key=${process.env.NEXT_PUBLIC_MAP_API}`, {
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     })
  //     .then((res) => {
  //       const country = res.data?.results.find((item: any) => item?.types.includes("country"))?.formatted_address
  //       setUserCountry(countryLocalization[country])
  //     });
  // };

  // const errorCallback = (error: any) => {
  //   console.log(error);
  // };

  const loadBanner = async () => {
    await axios
      .get(`${process.env.NEXT_PUBLIC_STRAPI_API_URL}/api/footer-ribbons`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${process.env.NEXT_PUBLIC_STRAPI_API_TOKEN}`,
        },
      })
      .then((res) => {
        setBannerText(res?.data?.data);
      });
  };

  return (
    <GlobalContext.Provider
      value={{
        bannerText,
        helpModalSave,
        setHelpModalSave,
        userCountry,
        setUserCountry,
        usercountryCode,
        country,
        isCookiePopup,
        setCookiePopup,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export { GlobalProvider, GlobalContext };
