import 'bootstrap/dist/css/bootstrap.min.css';
import '@/styles/globals.scss';
import type { AppProps } from 'next/app';
import { SSRProvider } from 'react-bootstrap';
import { Inter } from 'next/font/google';
import { GlobalProvider } from '@/components/context/Global';
import Head from 'next/head';

const inter = Inter({ subsets: ['latin'], variable: '--font-inter-tight', });

export default function App({ Component, pageProps }: AppProps) {
  return (
    <>
      
      <Head>
        <style>
          {`
            @import url('https://fonts.googleapis.com/css2?family=Inter+Tight:wght@100..900&display=swap');
          `}
        </style>
      </Head>
      <style jsx global>{`
        body {
          font-family: ${inter.style.fontFamily} !important;
        }
      `}</style>
      <SSRProvider>
        <GlobalProvider>
          <Component {...pageProps} />
        </GlobalProvider>
      </SSRProvider>
    </>
  );
}
